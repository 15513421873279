import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * Interface representing the state structure of the shopping cart.
 */
interface CartState {
  /**
   * A record storing item quantities, where the key is the item ID
   * and the value is the quantity.
   */
  quantities: Record<string, number>;
}

/**
 * Initial state of the cart.
 */
const initialState: CartState = {
  quantities: {},
};

/**
 * Redux slice for managing the shopping cart state.
 */
const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    /**
     * Increases the quantity of an item in the cart.
     *
     * @param state - The current cart state.
     * @param action - The action containing the item ID as a payload.
     */
    increaseQuantity: (state, action: PayloadAction<string>) => {
      const itemId = action.payload;
      state.quantities[itemId] = (state.quantities[itemId] || 0) + 1;
    },

    /**
     * Decreases the quantity of an item in the cart.
     * Ensures that the quantity does not go below zero.
     *
     * @param state - The current cart state.
     * @param action - The action containing the item ID as a payload.
     */
    decreaseQuantity: (state, action: PayloadAction<string>) => {
      const itemId = action.payload;
      state.quantities[itemId] = Math.max(
        0,
        (state.quantities[itemId] || 0) - 1
      );
    },

    /**
     * Empties the cart by resetting the quantities to an empty object.
     *
     * @param state - The current cart state.
     */
    emptyCart: (state) => {
      state.quantities = {}; // Reset the cart quantities to an empty object
    },
  },
});

// Exporting actions
export const { increaseQuantity, decreaseQuantity, emptyCart } =
  cartSlice.actions;

// Exporting the reducer
export default cartSlice.reducer;
