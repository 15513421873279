import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { RootState, persistor } from '../store/store';
import { GET_ME } from '../store/user/user.graphql.ts';
import i18n from '../translations/i18n.ts';

function useAuth() {
  const { isLoading, accessToken, refreshToken, role } = useSelector(
    (state: RootState) => state.auth
  );
  const [shouldDisconnect, setShouldDisconnect] = useState(false);

  // Verify actual token validity
  const { data, loading } = useQuery(GET_ME, {
    onCompleted(data) {
      // Set language based on user's language
      i18n.changeLanguage(data?.getMe?.language?.toLowerCase() || 'fr').catch();
    },
    onError() {
      setShouldDisconnect(true);
    },
  });

  useEffect(() => {
    if (shouldDisconnect) {
      persistor.purge();
      setShouldDisconnect(false);
    }
  }, [shouldDisconnect]);

  const isAuthenticated = useMemo(() => {
    if ((loading || isLoading) && !!accessToken) {
      return true;
    }
    return !!accessToken && !!data?.getMe?.id;
  }, [data, loading, accessToken]);

  return { isLoading, isAuthenticated, accessToken, refreshToken, role };
}

export default useAuth;
