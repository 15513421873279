import { gql } from '@apollo/client';

export const GET_OFFERS = gql`
  query GET_OFFERS {
    listAvailableOffers {
      offers {
        id
        title
        description
        price
      }
      packs {
        id
        title
        description
        price
        offers_include {
          id
          title
          description
        }
      }
    }
  }
`;

export const GET_CURRENT_OFFERS = gql`
  query listSubscribedOffers($user_id: String!) {
    listSubscribedOffers(user_id: $user_id) {
      offers {
        id
        title
        description
        price
      }
      packs {
        id
        title
        description
        price
        offers_include {
          id
          title
          description
        }
      }
      active_pack {
        id
        title
        description
        price
        offers_include {
          id
          title
          description
        }
        subscribed_at
        subscribe_end
      }
    }
  }
`;

export const GET_PRODUCTS = gql`
  query getProducts($type: String!) {
    getProducts(type: $type) {
      priceId
      id
      object
      active
      created
      default_price
      description
      images
      marketing_features
      livemode
      name
      package_dimensions
      shippable
      statement_descriptor
      tax_code
      unit_label
      updated
      url
      price
      currency
      metadata {
        type
        bonus
        quantity
      }
    }
  }
`;

export const GET_CHECKOUT_SESSION_URL = gql`
  query getCheckoutSessionUrl($products: [CheckoutProductItemInput!]!) {
    getCheckoutSessionUrl(products: $products)
  }
`;
