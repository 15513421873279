import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslations from '../translations/locales/en/translation.json';
import frTranslations from '../translations/locales/fr/translation.json';
import de from '../translations/locales/de/translation.json';
import es from '../translations/locales/es/translation.json';
import it from '../translations/locales/it/translation.json';
import pt from '../translations/locales/pt/translation.json';

const resources = {
  en: {
    translation: enTranslations,
  },
  fr: {
    translation: frTranslations,
  },
  de: {
    translation: de,
  },
  es: {
    translation: es,
  },
  it: {
    translation: it,
  },
  pt: {
    translation: pt,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'fr',
    fallbackLng: 'fr',
    debug: true,
    detection: {
      order: [
        'queryString',
        'cookie',
        'localStorage',
        'sessionStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
      ],
      caches: ['cookie'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
