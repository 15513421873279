import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { User } from '../../types/feelbat';
import { ERROR_CODES } from '../../utils/errorCodes';
import { getMe, fetchUserById, updateMe } from './user.thunk'; // Import your thunk

interface UserState {
  isLoading: boolean;
  errorCode: keyof typeof ERROR_CODES | string | null;
  selectedUser: User | null;
  me: User | null;
}

const initialState: UserState = {
  isLoading: false,
  errorCode: null,
  selectedUser: null,
  me: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUserError: (state) => {
      state.errorCode = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMe.pending, (state) => {
        state.isLoading = true;
        state.errorCode = null;
      })
      .addCase(getMe.fulfilled, (state, action: PayloadAction<User>) => {
        state.isLoading = false;
        state.me = action.payload;
      })
      .addCase(getMe.rejected, (state, action) => {
        state.isLoading = false;
        state.errorCode = action.error.message || 'UNEXPECTED_ERROR';
      })

      .addCase(fetchUserById.pending, (state) => {
        state.isLoading = true;
        state.errorCode = null;
      })
      .addCase(
        fetchUserById.fulfilled,
        (state, action: PayloadAction<User>) => {
          state.isLoading = false;
          state.selectedUser = action.payload;
        }
      )
      .addCase(fetchUserById.rejected, (state, action) => {
        state.isLoading = false;
        state.errorCode = action.error.message || 'UNEXPECTED_ERROR';
      })

      .addCase(updateMe.pending, (state) => {
        state.errorCode = null;
      })
      .addCase(updateMe.fulfilled, (state, action: PayloadAction<User>) => {
        state.me = action.payload;
      })
      .addCase(updateMe.rejected, (state, action) => {
        state.errorCode = action.error.message || 'UNEXPECTED_ERROR';
      });
  },
});

export const { clearUserError } = userSlice.actions;

export default userSlice.reducer;
